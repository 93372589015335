.overview {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.overview-table {
    width: 100%;
    flex: 3;
}

.overview-graph {
    width: 100%;
    flex: 2;
}

.positions-div {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.positions-div > div {
    flex: 1;
    width: 100%;
    margin: 10px 10px auto 10px;
}

.position-div-head {
    position: relative;
    border-radius: 7px;
    z-index: 2;
}

.positions-head {
    padding: 5px 7%;
    position: relative;
    height: 20vh;
}

.positions-head-tag {
    position: absolute;
    top: 0px;
    left: 5%;
    width: 30% !important;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    color: white;
    padding: 3px 10px;
    font-size: large;
    font-weight: 800;
    box-shadow: 2px 5px 29px #00000025;
}

.positions-head h1 {
    padding-top: 45px;
    padding-left: 0px;
    padding-bottom: 0px;
}

.positions-head h4 {
    color: #e1e1e1af;
    font-size: 15px;
    padding-left: 0px;
    padding-bottom: 0px;
}

.positions-head h3 {
    color: #F1F1F1;
    font-size: 20px;
    padding-left: 0px;
    padding-bottom: 0px;
}

.positions-head h5 {
    position: absolute;
    font-size: 15px;
    bottom: 10px;
    right: 7%;
    padding: 5px 10px;
    background-color: #f1f1f167;
    border-radius: 7px;
    color: #f1f1f1;
}

.positions-head-variation {
    position: absolute;
    bottom: 20px;
    left: 7%;
}

.positions-head-chart {
    position: absolute;
    top: 7%;
    right: 4%;
}

.positions-table {
    position: relative;
    width: 97%;
    margin-top: -5vh !important;
    padding-top: 5vh;
    color: #1F1F1F;
    margin: auto;
    border-radius: 7px;
    backdrop-filter: blur(2px);
    box-shadow: 2px 5px 29px #00000025;
    z-index: 1;
}

.position-predict-arrow {
    position: absolute;
    top: 0px;
    right: 0px;
}

.position-tooltip-zoom {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 2;
    cursor: pointer;
}

.position-tooltip-zoom-content p {
    font-size: 15px;
    margin: 0px auto 0px 0px;
}

/* 
.MuiTableBody-root > tr {
    background-color: red;
} */

.bottom-div {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    height: 95%;
}

.resume-div {
    flex: 3;
    background: linear-gradient(to right bottom, #E33D76, #e7769ede);
    height: 100%;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.resume-title {
    position: absolute;
    top: 0px;
    left: 5%;
    width: 45% !important;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    color: white;
    padding: 3px 10px;
    font-size: 25px;
    font-weight: 800;
    box-shadow: 2px 5px 29px #00000025;
    background-color: #f1f1f19e;
}

.resume-donut {
    margin-top: 10%;
    margin-right: 35%;
}

.history-title {
    position: absolute;
    top: 0px;
    left: 5%;
    width: 20% !important;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    color: white;
    padding: 10px 10px;
    box-shadow: 2px 5px 29px #00000025;
    background-color: #e33d779c;
    z-index: 2;
}

.history-title h1 {
    padding: 0px;
    font-size: 24px;
}

.history-chart {
    flex: 6;
    box-shadow: 2px 5px 29px #00000025;
    padding: 10px 5px 10px 5px;
    height: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
}

.button-div {
    flex: 0.5;
    width: 100%;
    height: 100%;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    background: linear-gradient(to right bottom, #E33D76, #e7769ede);
    position: relative;
    display: flex;
    flex-direction: column;
}

.button-div > div {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: auto;
    background-color: white;
    display: flex;
    cursor: pointer;
}

.fund-div {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0px;
    right: 0px;
    width: 35%;
    height: 100%;
}

.fund-div > div {
    position: relative;
    background-color: #f1f1f1be;
    margin: auto 0px;
    height: 70%;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.fund-div h1 {
    font-size: 30px;
    padding: 0px;
    margin:  10% auto 0% 10%;
}

.fund-div h3 {
    font-size: 15px;
    color: #8c8c8c;
    padding: 0px;
    margin:  10% auto 0% 10%;
}

.fund-div h5 {
    font-size: 17px;
}

.fund-div > div > div {
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.fund-div > div > div > div {
    width: 100%;
    text-align: center;
    padding: 10px 10px 5px 10px;
    color: #F1F1F1;
}

.modal-last-days {
    width: 80%;
    height: 80vh;
    margin: auto;
    margin-top: 10vh;
    background-color: #F1F1F1;
    border: none;
    position: relative;
    border-radius: 7px;
    overflow: auto;
}

/* width */
.modal-last-days::-webkit-scrollbar {
    width: 10px;
}
  
  /* Track */
.modal-last-days::-webkit-scrollbar-track {
    border-radius: 7px;
}
  
  /* Handle */
.modal-last-days::-webkit-scrollbar-thumb {
    background: #775dd0;
    border-radius: 7px;
}

.modal-title {
    position: absolute;
    background-color: #775dd0;
    top: 0px;
    left: 5%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.modal-close-icon {
    position: absolute;
    top: 1.5vh;
    right: 1%;
    cursor: pointer;
}

.modal-last-days-content {
    position: absolute;
    top: 9vh;
    left: 5%;
    right: 5%;
}

.modal-last-days-resume {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 5%;
    width: 100%;
    height: 20vh;
    border-radius: 7px;
    margin-bottom: 3vh;
    padding-left: 3%;
    padding-right: 3%;
    background-image: linear-gradient(to right bottom, #775dd0, #775dd0e0);
}

.modal-last-days-resume h1 {
    position: absolute;
    width: 20% !important;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    color: white;
    padding: 10px 10px;
    box-shadow: 2px 5px 29px #00000025;
    background-color: #f1f1f19e;
    font-size: 25px;
    z-index: 2;
}

.modal-last-days-resume h3 {
    color: #F1F1F1;
    font-weight: 800;
    font-size: 35px;
}

.modal-last-days-resume h4 {
    font-size: 20px;
}

.modal-last-days-resume h5 {
    font-size: 18px;
    opacity: 0.8;
}

.modal-last-days-tags {
    flex: 4;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    overflow: hidden;
    background-color: #f1f1f167;
    border-radius: 7px;
    margin-top: 5vh;
    height: fit-content;
    margin-left: 20px;
}

.modal-last-days-tags > div {
    width: 100%;
    padding: 0% 2% 1% 2%;
}

.modal-last-days-tags h3 {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    color: white;
    padding: 10px 10px;
    box-shadow: 2px 5px 29px #00000025;
    background-color: #f1f1f19e;
    font-size: 18px;
    z-index: 2;
    width: fit-content;
}

.modal-last-days-tags > div > div {
    display: flex;
    flex-direction: row;
}

.modal-last-days-tags > div > div > h4 {
    margin: auto auto auto 10%;
}

.modal-last-days-tags > div > h4 {
    color: #F1F1F1;
    text-align: center;
    font-size: 23px;
}

.modal-last-days-deposits {
    flex: 1.5;
    max-height: 70%;
    height: fit-content;
    overflow: auto;
    background-color: #f1f1f167;
    padding: 0% 1% 1% 1%;
    border-radius: 7px;
    margin-top: 5vh;
}

.modal-last-days-deposits h3 {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    color: white;
    padding: 10px 10px;
    box-shadow: 2px 5px 29px #00000025;
    background-color: #f1f1f19e;
    font-size: 20px;
    z-index: 2;
}

.modal-last-days-deposits h4 {
    margin: auto;
    color: #5edd23;
}

.modal-last-days-deposits p {
    margin: 0px auto;
    color: #F1F1F1;
}

/* width */
.modal-last-days-deposits::-webkit-scrollbar {
    width: 10px;
}
  
  /* Track */
.modal-last-days-deposits::-webkit-scrollbar-track {
    border-radius: 7px;
}
  
  /* Handle */
.modal-last-days-deposits::-webkit-scrollbar-thumb {
    background: #f1f1f1c7;
    border-radius: 7px;
}
