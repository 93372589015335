// Customize any SASS variables here
@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
@import "~bootstrap/scss/bootstrap.scss";

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  background-color: #f1f1f1;
}
body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.page {
  display: flex;
  flex-direction: row;
}

.page-side {
  flex: 1;
}

.page-container {
  flex: 7;
  margin: 1rem;
}

@import "styles/header";
@import "styles/layout";
@import "styles/panels";
