.panels {
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  width: 100%;
  height: 95vh;
}

.panel-first {
  flex: 1;
  padding: 0px 20px;
}

.panel-second {
  padding-top: 3vh;
  flex: 1;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.panel-positions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.panel-position {
  margin-top: 0px;
  font-size: 5px;
}

.panel-allocation {
  flex: 1;
  position: relative;
}

.panel-allocation-fund {
  position: absolute;
  width: 52%;
  height: 90%;
}

.panel-balance {
  position: relative;
  flex: 2;
  height: 100%;
}

.panel-etf {
  flex: 1;
  margin: 5px 5px 5px 5px;
}

.panel-action {
  flex: 1;
  margin: 5px 5px 5px 5px;
}

.panel-other {
  flex: 1;
  margin: 5px 5px 5px 5px;
}

.k-grid-header {
  padding: 0px !important;
}

/*.panels {
  display: grid;
  //grid-gap: 10px;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
}
.panel-info {
  display: none;
  grid-row: span 2;
}
.panel-allocation {
  grid-column: span 1;
}
.panel-balance {
  grid-column: span 10;
}
.panel-positions {
  grid-column: span 2;
}
.panels > div {
  //border: 1px solid black; 
}

@media (max-width: 750px) {
  .panels {
    grid-template-columns: 225px auto;
    grid-template-rows: auto auto auto;
  }
  .panel-positions {
    grid-column: span 2;
  }
}

@media (max-width: 500px) {
  .panels {
    grid-template-columns: auto;
  }
  .panel-positions {
    grid-column: span 1;
  }
}
*/
