.button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin: auto 10px auto 30px;
  height: 100%;
  width: 100px;
  backdrop-filter: blur(1.5px);
  border-radius: 10px;
  background-color: #ffffff34;
  color: #ffffff;
}

.button:hover {
  cursor: pointer;
  background-color: #ffffff0e;
}

.MuiListItem-root.item {
  margin: 5px auto;
  width: 92%;
  border-radius: 10px;
  color: #1F1F1F;
}

.MuiListItem-root.item-selected {
  margin: 5px auto;
  width: 92%;
  border-radius: 10px;
  background-image: linear-gradient(to right bottom, #0093e9, #80c5d0e0);
  color: #ffffff;
}

.panel-info {
  display: block;
  position: relative;
  justify-content: center;
  width: 30%;
  height: 43vh;
}

.panel-info-total {
  position: absolute;
  top: 0;
  left: 2.5%;
  width: 95%;
  height: 17vh;
  min-height: 17vh;
  backdrop-filter: blur(1.5px);
  padding-top: 20px;
  padding-left: 30px;
  border-radius: 10px;
  box-shadow: 2px 5px 29px #00000044;
  color: #ffffff;
  overflow: hidden;
}

.panel-info-total h3 {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
}

.panel-info-total-chart {
  float: right;
  margin-right: 6%;
  margin-top: -2%;
}

.panel-info-resum {
  position: absolute;
  top: 13.5vh;
  right: 7%;
  color: #ffffff;
  background-color: #ffffff5b;
  padding: 0px 10px;
  border-radius: 6px;
  font-weight: bold;
}

.panel-info-data {
  position: absolute;
  top: 2.5vh;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  min-height: 22vh;
  padding: 5px;
  padding-top: 14.5vh;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  box-shadow: 2px 5px 29px #00000025;
}


.panel-info-data::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
}

.panel-info-data::-webkit-scrollbar-track {
  background: #F1F1F1;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  /* color of the tracking area */
}

.panel-info-data::-webkit-scrollbar-thumb {
  background-color: #cececee6;
  border-radius: 8px;
  /* color of the scroll thumb */
  /* border-radius: 20px; */
  /* roundness of the scroll thumb */
  border: 3px solid #cececee6;
  /* creates padding around scroll thumb */
}

.price {
  margin: 0px auto 0px 5px;
  font-size: 35px;
  font-weight: bolder;
}

.red {
  color: red;
}

.green {
  color: #53b326;
}

.icon {
  border-radius: 50%;
  padding: 2px;
  margin-right: 3px;
}

.panel-chartelineOverlay {
  position: absolute;
  width: 20%;
  height: 5vh;
  margin-left: 4%;
  border-radius: 10px;
  backdrop-filter: blur(1.5px);
  background-image: linear-gradient(to right bottom, #775dd0, #775dd0e0);
  background-color: #ffffff44;
  -webkit-box-shadow: 2px 5px 29px -12px #000000;
  box-shadow: 2px 5px 29px #00000044;
}

.panel-charteline {
  position: absolute;
  width: 100%;
  height: 83%;
  margin-top: 2%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 10px 20px #0000002a;
}

.panel-donut {
  width: 100%;
  height: 97%;
  min-height: 30vh;
  padding-top: 15%;
  position: "relative";
  margin-top: 2vh;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 2px 5px 29px -12px #000000;
  box-shadow: 0px 10px 20px #0000002a;
}

.panel-donut-overlay {
  position: absolute;
  width: 70%;
  height: 5vh;
  top: 0;
  margin: 0% 8%;
  border-radius: 10px;
  backdrop-filter: blur(1.5px);
  background-image: linear-gradient(to right bottom, #de2767, #e63671e0);
  background-color: #ffffff44;
  box-shadow: 2px 5px 29px #00000044;
}

.panel-TablePosition-cellule {
  font-size: 12px;
  padding: "5px";
}

.panel-fund-detail {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 50%;
  height: 35vh;
  width: 45%;
  padding: 1vh 5% 5vh 0px;
  border-radius: 10px;
  margin: 4vh 0px 2vh 0px;
  backdrop-filter: blur(2px);
  box-shadow: 2px 5px 29px #00000025;
}

.panel-fund-detail-title {
  font-size: 22px;
  margin: 1vh 0px 4vh 0px;
  background-image: linear-gradient(to right bottom, #de2767, #e63671e0);
  border-radius: 0px 10px 10px 0px;
  width: 70%;
  padding: 0.5vh 0px 0.5vh 15%;
  color: #ffffff;
  box-shadow: 2px 5px 29px #00000044;
}

.panel-fund-detail-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff;
  margin: 7px 0% 7px 18%;
  border-radius: 10px;
  padding: 6px 15px;
  background-image: linear-gradient(to right bottom, #0093e9, #80c5d0e0);
}

.panel-fund-detail-text p {
  margin: 0px;
}

.panel-history {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 94vh;
  margin-top: 6vh;
}

.panel-history-date {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin-left: 2%;
  padding: 0.5% 1%;
  border-radius: 10px;
  background-image: linear-gradient(to right bottom, #775dd0, #775dd0e0);
  background-color: #ffffff44;
  -webkit-box-shadow: 2px 5px 29px -12px #000000;
  box-shadow: 2px 5px 29px #00000044;
}

.panel-history-accordion {
  margin: 5vh 7%;
  padding-bottom: 5vh;
}

.panel-history-chart {
  flex: 1;
  width: 100%;
  height: 32vh;
  position: relative;
}

.panel-history-chartline {
  position: absolute;
  width: 97%;
  height: 32vh;
  right: 0px;
  padding: 0vh 1% 0vh 43%;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  box-shadow: 2px 5px 29px #00000025;
}

.panel-history-details {
  position: absolute;
  padding: 1.2vh 1.2%;
  height: 30vh;
  width: 45%;
  margin-top: 1vh;
  border-radius: 10px;
}

.panel-history-details-header {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding-top: 0.5vh;
  color: #ffffff;
  background-color: #ffffff86;
  box-shadow: 2px 5px 29px #00000025;
}

.panel-history-details-header p {
  margin: auto;
}

.panel-history-details-header-div {
  flex: 1;
  height: 10vh;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.panel-history-details-body {
  flex: 2;
  padding: 2vh 5%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.panel-history-details-body div p {
  width: "100%";
  text-align: center;
}

.text-overlay {
  background-color: #ffffff75;
  color: #ffffff;
  border-radius: 10px;
  padding: 0.3vh 6%;
  width: fit-content;
  min-width: 30%;
  text-align: center;
  margin: 2% auto 10% auto;
}

.scroll-bar::-webkit-scrollbar {
  width: 12px;
}

.scroll-bar::-webkit-scrollbar-track {
  background: #e0e0e04f;
  border-radius: 10px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #9999995d;
  border-radius: 20px;
}

.panel-details {
  height: 100vh;
  padding-top: 10vh;
  width: 100%;
  overflow: hidden;
}

.panel-flex {
  display: flex;
  flex-direction: row;
  margin: 0px 2%;
}

.panel-details-position {
  flex: 1.5;
  display: block;
  position: relative;
  justify-content: center;
  width: 30%;
  height: 45vh;
}

.panel-details-allocation {
  position: relative;
  flex: 1;
  height: 100%;
  width: 96%;
  margin: auto auto auto 2%;
}

.panel-details-performance{
  position: relative;
  flex: 2;
  height: 100%;
}


